import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import Slider from 'react-slick'
import { useEffect, useRef, useState } from 'react'
import IconPlay from '@icons/Play'
import IconCarouselArrowRight from '@icons/CarouselArrowRight'
import IconCarouselArrowLeft from '@icons/CarouselArrowLeft'
import Image from '@global/Image'
import { defaultImages } from '@lib/variables'
import { formatDate } from '@lib/date'
import dynamic from 'next/dynamic'
import * as S from './GalleryMain.styled'
import { getYoutubeThumbnail } from '@lib/helpers'
import Head from 'next/head'

const ReactPlayer = dynamic(() => import('react-player'), { ssr: false })

const NextArrow = ({ onClick, className }) => {
  return (
    <S.CarouselNext onClick={onClick} className={className}>
      <IconCarouselArrowRight />
    </S.CarouselNext>
  )
}

const PrevArrow = ({ onClick, className }) => {
  return (
    <S.CarouselPev onClick={onClick} className={className}>
      <IconCarouselArrowLeft />
    </S.CarouselPev>
  )
}

const GalleryMain = ({
  items = [],
  initialSlide,
  onIndexChange,
  fullwidth,
  onImageClick,
  isVideoButtonClick,
  address,
  created,
}) => {
  const [isVideoPlaying, setVideoPlaying] = useState(false)
  const slider = useRef(null)

  const settings = {
    dots: false,
    infinite: false,
    speed: 600,
    slidesToShow: 1,
    slidesToScroll: 1,
    lazyLoad: true,
    focusOnSelect: false,
    draggable: false,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    beforeChange: (_, newIndex) => {
      if (!isVideoButtonClick) {
        setVideoPlaying(false)
      }
      onIndexChange && onIndexChange(newIndex)
    },
  }

  useEffect(() => {
    slider.current.slickGoTo(initialSlide)
  }, [initialSlide])

  useEffect(() => {
    if (isVideoButtonClick) {
      setVideoPlaying(true)
    }
  }, [isVideoButtonClick])

  const getImageAltText = (index) => {
    return `${address || ''} - Image ${index}${
      created ? ` - ${formatDate(created, 'dd/MM/yyyy')}` : ''
    }`
  }

  return (
    <>
      <Head>
        {items.length > 0 && (
          <link rel='preload' href={items[0].href} as='image' />
        )}
      </Head>
      <S.GalleryMain fullwidth={fullwidth}>
        <Slider ref={slider} {...settings}>
          {items.map((it, key) => (
            <S.GalleryItem key={`gallery-${key}`}>
              {it.link_type === 'image' || it.link_type === 'floorplans' ? (
                <Image
                  src={it.href}
                  fallbackImage={defaultImages.mediumNavy}
                  onClick={() => onImageClick && onImageClick(key)}
                  quickload
                  alt={getImageAltText(key + 1)}
                />
              ) : it.link_type === 'video' ? (
                <ReactPlayer
                  playing={isVideoPlaying}
                  light={
                    it?.href?.href
                      ? getYoutubeThumbnail(it.href.href)
                      : defaultImages.mediumNavy
                  }
                  url={it.href.href}
                  width='100%'
                  height='100%'
                  playIcon={<IconPlay onClick={() => setVideoPlaying(true)} />}
                />
              ) : it.link_type === 'tour' ? (
                <iframe src={it.href} width='100%' height='100%' />
              ) : null}
            </S.GalleryItem>
          ))}
        </Slider>
      </S.GalleryMain>
    </>
  )
}

export default GalleryMain
